@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }

  70%,
  90% {
    width: 100%;
  }
}

.page {
  .target_banner {
    #target_part {
      polygon {
        fill: #e2cc83;
      }
      .slide_cover {
        h6 {
          font-size: 17px;
          color: white;
          margin: auto;
          @media (max-width: 992px) {
            display: block;
          }
          @media (min-width: 993px) {
            display: none;
          }
        }
      
        h4 {
          color: black;
          font-size: 40px;
          position: relative;
          text-transform: uppercase;
          -webkit-text-stroke: 0.07vw white;
          @media (max-width: 992px) {
            display: none;
          }
          &:before {
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            color: white;
            overflow: hidden;
            position: absolute;
            content: attr(data-text);
            border-right: 2px solid white;
            -webkit-text-stroke: 0vw white;
            animation: animate 6s linear infinite;
            @media (max-width: 992px) {
              display: none;
            }
          }
        }
      }
    }
  }
}
