a {
    cursor: pointer;
}
iframe {
    width: 100%;
    border: none;
    min-height: 600px;
}
.custom-modal {
    z-index: 999999999999999999999999;
}
.late_cat {
    margin: 0 0 0 10px;
    background: RGB(255, 14, 14);
    padding: 5px 10px;
    color: white;
    border-radius: 11px;
}
.late_cat_text {
    color: RGB(255, 14, 14);
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: 0.25rem;
    // margin-bottom: 1.5rem;
    border: 1px solid #dadada;
    background: #637d8914;
    border-radius: 10px;

    margin-top: 20px;
    h3 {
        font-size: 120px;
    }
    h4 {
        color: black;
    }
    .price {
        font-size: 95px;
        color: #e2cc83;
    }
    .Payment_img {
        height: 200px;
        width: 200px;
    }
    .button_payment {
        background: #e2cc83;
        border: none;
        margin-top: 30px;
        color: white;
    }
    .referal {
        background: #e6be38;
        padding: 6px;
        border-radius: 14px;
        color: #fffbfb;
        font-size: 14px;
        font-weight: bold;

        border: none;
        &:focus {
            outline: 0 !important;
        }
        &:active {
            outline: 0 !important;
        }
    }
    .text-secondary {
        padding: 6px 0;

        font-weight: bold;
    }
    .text-main {
        padding: 6px 0;
    }
}
.rounded {
    border-radius: 50%;
}
.button_payment {
    background: #e2cc83;
    border: none;
    color: white;
}
.btn:focus {
    outline: 0 !important;
    outline-offset: none !important;
    border: 0 !important;
}

.bg-primary {
    background-color: #e2cc83 !important;
}
.me-2 {
    margin-right: 0.5rem !important;
}
.active {
    color: #23d160;
}
.M_t_17 {
    margin-top: 17%;
}
.link {
    color: #e2cc83;
    font-size: 15px;
    font-weight: bold;
}
.px_fluid {
    padding: 0 1%;
}
.link_footer {
    color: black;
    font-weight: bold;
}
.RFS-StepperContainer {
    padding: 20px 0;
}
.submit_btn {
    min-width: 200px;
}
.Center {
    display: block;
    margin: auto;
}
.sub_list {
    border: 1px solid #dadada;
    padding: 6px 12px;
    border-radius: 20px;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #f3f5f6;
    opacity: 1;
    border-radius: 15px;
    border: 1px solid #dadada;
}
.PhoneInputInput {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-login-user {
    margin-left: 60px;
    #dropdown-user {
        padding: 0;
        display: flex !important;
        span {
            padding: 10px 0;
            color: #e2cc83;
        }
        &:focus {
            outline: 0 !important;
            border: 0 !important;
        }
        &:active {
            outline: 0 !important;
            border: 0 !important;
        }
    }
    .dropdown-toggle::after {
        position: absolute;
        right: 0;
        bottom: 18px;
        color: #e2cc83;
    }
    .dropdown-menu {
        background: #f3f5f6 !important;
        min-width: 240px;
        .dropdown-item {
            padding: 0 !important;
            a {
                &:after {
                    display: none;
                }
                &:before {
                    display: none;
                }
            }
            h6 {
                font-size: 17px;
                color: #1b1717;
            }
        }
    }
}
.avatar {
    img {
        width: 40px;
        border-radius: 50% !important;
    }
}
.toast-group {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 20;
    display: flex;
    flex-direction: column-reverse;
    .toast-custom {
        position: relative;
        margin-top: 10px;
        bottom: 0;
        right: 0;
    }
}
// Toast Style
.toast-custom {
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 20;
    background-color: #1d1d1d;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    max-width: calc(100vw - 40px);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.15);
    color: #000;
    direction: ltr;
    &.success {
        background-color: #23d160;

        .toast-message {
            color: #fff;
        }
    }

    &.danger {
        background-color: #ff3860;

        .toast-message {
            color: #fff;
        }
    }

    &.warning {
        background-color: #ffdd57;

        .toast-message {
            color: #000;
        }
    }

    .toast-message {
        font-weight: bold;
        font-size: 16px;
        margin-right: 1rem;
        color: #b5b5b5;
    }
}
.crop-modal {
    .modal-lg {
        max-width: 600px;
    }
}
.crop-container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 500px;
    max-height: calc(100vh - 200px);
    background-color: #000000;
}
.crop-action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}
.login_button {
    background: #e2cc83;
    border: #e2cc83;
    font-size: 20px;
    font-weight: bold;
    color: white;
}
.back_btn {
    background: #83a5e2;
    border: #83a5e2;
    font-size: 20px;
    font-weight: bold;
    color: white;
}
.custom-pagination {
    position: absolute;
    bottom: 15%;
    /* justify-content: center; */
    text-align: center;

    width: fit-content;
    min-width: 300px;
    left: calc(50% - 150px);
    display: flex;
    justify-content: center;

    border-radius: 13px;
    h6 {
        color: #000000;
        border: 2px solid #e2cc83;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        font-size: 20px;
        font-weight: bold;
        padding: 1px;
        background: #e2cc83;
    }
}

.alert-success {
    color: #ffffff;
    background-color: #1a9f3a;
    border-color: #1a9f3a;
    text-align: center;
    p {
        color: white;
        font-size: 20px;
    }
}
.alert-danger {
  
    background-color: #ca0a1c;
    border-color: #ca0a1c;
    text-align: center;
    p {
        color: white;
        font-size: 20px;
    }
}
@import "./vars.scss";
@import "./pages.scss";
@import "./login.scss";
@import "./loader.scss";
@import "./textanimation.scss";
