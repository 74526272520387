.wrap-pages{
    min-height: 100vh;
}   
.page{
    padding-bottom: 50px;
    padding-top: 130px;
    min-height: calc(100vh - (130px - 60px));
    .title{
        margin-top: 30px;
    }
    .page_title{
        margin: auto;
        margin-top: 30px;
    }

}
.uplode_img{
    position: relative;
    width: min-content;
    a{
        img{
            position: absolute;
            left: calc(50% - 20px);
            bottom: 8px;
            width: 40px;
            cursor: pointer;
        }
    }
}

// custom
.dropdown-menu{
background: #f3f5f6;
.dropdown-item{

    padding: 10px 15px !important;
    color: #444141 !important;
    border-bottom: 1px solid #444141 !important;
    &:after ,&:before{
    display: none;
    }

}
}
.page_list{
    li{
        font-size: 20px !important;
        span{
            color: $main_color;
            font-weight: bold !important;
        }
    }
}
.ICBS_span{
    color: $main_color;
    font-weight: bold;
}
.priv_title{

    font-size: 25px;
    color: #e2cc83;
    margin-top: 26px;

}