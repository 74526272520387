.login-page {
    min-height: 100vh;
    .login_container {
        min-height: 100vh;
    }
    .login_title {
        padding-bottom: 10px ;
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    .image_container {
        background-size: cover;
        @media (max-width: 1400px) {
            display: none;
         }
        .overlay {
            
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #000000a8;
            .btn-back{
                img{
                    width: 15px;
                float: right;
                margin-right: 25px;
                margin-top: 20px;
                }
                
            }
            .logo_data {
      
                width: fit-content;
                margin-top: auto;
                display: block;
                margin: auto;
                margin-top: 40%;
                p {
                    font-size: 29px;
                    color: antiquewhite;
                }
                .main_logo {
                    width: fit-content;
                    margin: auto;
                    img {
                        width: 100px;
                    }
                    h3 {
                        font-size: 45px;
                    
                    }
                   
                }
            }
        }
    
    }
  
    .login-form {
        width: 750px;
        height: fit-content;
        padding: 30px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        
        background: white;

     
    }
}
.error_mesage {
    background: #f3dada;
    padding: 10px;
    border-radius: 6px;
    p{
        color: #ed0404;
        font-size: 16px;
    }
    ul{
        margin-left: 20px;
        list-style: disc;
        color: #9b1111;
    }
}
.succses_mesage{
    background: #53a65340;
    padding: 10px;
    border-radius: 6px;
    p{
        color: #53a653;
        font-size: 16px;
    }
    ul{
        margin-left: 20px;
        list-style: disc;
        color: 	#53a653;
    }
}